const logotext = "WalldoffStudios";
const meta = {
    title: "Fredrik Walldoff",
    description: "I’m Fredrik Walldoff Game developer _ Game developer, currently working in Stockholm",
};

const introdata = {
    title: "I’m Fredrik Walldoff",
    animated: {
        first: "Unity developer",
        second: "Shader developer",
        third: "Tech artist",
    },
    description: "Game developer with almost 5 years of experience with Unity's game engine",
    your_img_url: "https://images.unsplash.com/photo-1604964432806-254d07c11f32?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  
};

const dataabout = {
    title: "About me",
    aboutme: "",
};
const worktimeline = [{
    
    jobtitle: "Walldoff studios (Personal)",
    where: "Stockholm",
    date: "June 2019",
    },
    {
        jobtitle: "UI programmer (Internship)",
        where: "Gold town games, skellefteå",
        date: "June 2021 - August 2021",
    },
    {
        jobtitle: "Network programmer (Internship)",
        where: "Remote (Stockholm)",
        date: "January 2022 - June 2022",
    },
    {
        jobtitle: "Unity Developer",
        where: "eEducation Albert AB",
        date: "June 2022 - October 2023",
    },
    {
        jobtitle: "Unity Developer",
        where: "Safe lane gaming",
        date: "October 2023 - present",
    },
];

const skills = [{
        name: "Unity",
        value: 90,
    },
    {
        name: "C#",
        value: 90,
    },
    {
        name: "HLSL",
        value: 70,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Javascript",
        value: 60,
    },
    {
        name: "CSS",
        value: 60,
    },
    {
        name: "HTML",
        value: 60,
    },
    {
        name: "Firebase",
        value: 40,
    },
];

const services = [{
        title: "Unity developer",
        description: "Almost 5 years of experience working with Unity. I have created and released 2 games on google play store and created 2 assets for unity asset store, where one is currently awaiting approval",
    },
    {
        title: "Tech artist",
        description: "I have been in charge of developing visual effects with the help of custom HLSL shaders at my last 2 jobs, and I've created and released a 5 star shader asset on unity asset store and another one is awaiting approval.",
    },
    {
        title: "Web development",
        description: "I have in my spare time created various websites with react, css and html and I'm currently developing an online asset store to sell my shader and 3D assets",
    },
];

const dataportfolio = [{
        img: require("./assets/images/TrailShader/trailShaderThumbnail.webp"),
        description: "Awaiting approval",
        link: "https://assetstore.unity.com/publishers/66964",
    },
    {
        img: require("./assets/images/TrailShader/trailShaderSnow.webp"),
        description: "Awaiting approval",
        link: "https://assetstore.unity.com/publishers/66964",
    },
    {
        img: require("./assets/images/TrailShader/trailShaderSand.webp"),
        description: "Awaiting approval",
        link: "https://assetstore.unity.com/publishers/66964",
    },
    {
        img: require("./assets/images/TrailShader/trailShaderDirt.webp"),
        description: "Awaiting approval",
        link: "https://assetstore.unity.com/publishers/66964",
    },
    {
        img: require("./assets/images/TrailShader/trailShaderDirt2.webp"),
        description: "Awaiting approval",
        link: "https://assetstore.unity.com/publishers/66964",
    },
    {
        img: require("./assets/images/Indicator/indicatorThumbnail.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/coneIndicator.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/lineIndicator.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/parabolicIndicator.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/circleIndicators.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/fillEffects.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/targetIndicator.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/Indicator/mobileControls.webp"),
        description: "Aim indicators - 5 star rated on Unity asset store",
        link: "https://assetstore.unity.com/packages/vfx/shaders/aim-indicators-with-hit-detection-242438",
    },
    {
        img: require("./assets/images/VeganTD/veganTD6.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "",
    },
    {
        img: require("./assets/images/VeganTD/veganTD2.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/VeganTD/veganTD1.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/VeganTD/veganTD8.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/VeganTD/veganTD4.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/SnowboardMania/snowboard0.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/SnowboardMania/snowboard1.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
    {
        img: require("./assets/images/SnowboardMania/snowboard2.webp"),
        description: "Unavailable due to pending privacy policy update in the game.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "walldoffstudios@gmail.com",
    description: "Thank you for visiting my portfolio! If you have any questions, need assistance, or are interested in recruiting me for a project, please don't hesitate to get in touch. I look forward to hearing from you!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_6772crb",
    YOUR_TEMPLATE_ID: "template_j70m2jd",
    YOUR_USER_ID: "QEbsL8jQBXMta8T4q",
};

const socialprofils = {
    linkedin: "https://www.linkedin.com/in/fredrik-walldoff/",
    unity: "https://assetstore.unity.com/publishers/66964",
    youtube: "https://www.youtube.com/@walldoffstudios",
    instagram: "https://www.instagram.com/walldoffstudios/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};